import { DropEvent, FileRejection } from 'react-dropzone'
import { Prisma, PrismaClient } from '@prisma/client'
import { DefaultArgs } from '@prisma/client/runtime/library'
import { JSONContent } from '@tiptap/core/dist/packages/core/src/types'

export type TreeItemWithName = {
  id: string
  name: string
  children?: TreeItemWithName[] | null
}

export type TreeItem = {
  id: string
  children?: TreeItem[] | null
}

export type UploadInputProps = {
  label?: string
  required?: boolean
  url?: string
  errorMessage?: string
  onDrop: (
    acceptedFiles: File[],
    fileRejections?: FileRejection[],
    event?: DropEvent,
  ) => void | Promise<void>
  isUploading?: boolean
  onDelete?: (items: { name: string; mimeType: string; url: string }[]) => void
}

export const CategoryName = {
  Learn: 'Learn',
  ResourceLibrary: 'Resource Library',
} as const

export type PrismaTransaction = Omit<
  PrismaClient<Prisma.PrismaClientOptions, never, DefaultArgs>,
  '$extends' | '$transaction' | '$disconnect' | '$connect' | '$on' | '$use'
>

export type OrderDirection = 'desc' | 'asc'
export type URLParams = Record<string, string | number | boolean | Array<string>>

export type AmplitudeEventProperties = Record<string, unknown> | undefined

export type RecipeElementDirections = {
  make: JSONContent[]
  prep: JSONContent[]
}
